<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-right">
            <router-link
              :to="{ name: 'orden-trabajo-index' }"
            >
              <button class="btn btn-white border border-light mr-2 px-4 py-1">
                Regresar
              </button>
            </router-link>
            <button
              class="btn btn-success mr-2 px-4 py-1"
            >
              Guardar
            </button>
            <button
              class="btn btn-success px-4 py-1"
            >
              Finalizar
            </button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-1 text-right">
                <label><b>Paciente:</b></label>
              </div>
              <div class="col-sm-2 pl-0">
                Jorge Ledezma
              </div>
              <div class="offset-sm-7 col-sm-2 text-right">
                <b>Orden N° 22/23</b>
              </div>
            </div>
            <div class="row border-bottom border-warning">
              <div class="col-sm-1 text-right">
                <label><b>Servicio:</b></label>
              </div>
              <div class="col-sm-2 pl-0">
                Evaluación de Fonoaudiología
              </div>
              <div class="offset-sm-7 col-sm-2 text-right">
                (VS: 233/23)
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 text-right">
                <button class="btn btn-orange text-white">1: FONOAUDIOLOGÍA</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b-tabs>
              <b-tab title="Datos Generales" title-link-class="px-4 py-2" active>
                <div class="row mb-3">
                  <div class="offset-sm-7 col-sm-3 row">
                    <div class="offset-sm-2 col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Fecha:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <date-picker
                        class="w-100"
                        v-model="paso1.fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-sm-1 text-right">
                    <label class="col-form-label">
                      Hora Inicio:
                    </label>
                  </div>
                  <div class="col-sm-1 px-0">
                    <input type="time" class="form-control">
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <h5><b>DATOS PERSONALES</b></h5>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Nombres:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Apellidos:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-4 text-right">
                      <label class="col-form-label">F/Nacimiento:</label>
                    </div>
                    <div class="col-sm-4 px-0">
                      <date-picker
                        class="w-100"
                        v-model="paso1.fechaNacimiento"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-4 pr-0">
                      <label class="col-form-label">3 años - 10 meses</label>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Dirección:</label>
                  </div>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-4 text-right">
                      <label class="col-form-label">Celular:</label>
                    </div>
                    <div class="col-sm-2 px-0">
                      <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-6 pr-0">
                      <input type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-1 px-0 text-right">
                    <label class="col-form-label">Grado Escolar:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Institución:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-4 text-right">
                      <label class="col-form-label">Turno:</label>
                    </div>
                    <div class="col-sm-8 px-0">
                      <input type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Padre:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Ocupación:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-5">
                      <date-picker
                        class="w-100"
                        v-model="paso1.padre_fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <!-- <div class="col-sm-7">
                      <label class="col-form-label"><b>Edad: 30 años - 10 meses</b></label>
                    </div> -->
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Madre:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Ocupación:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-5">
                      <date-picker
                        class="w-100"
                        v-model="paso1.madre_fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <!-- <div class="col-sm-7">
                      <label class="col-form-label"><b>Edad: 30 años - 10 meses</b></label>
                    </div> -->
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-sm-1 pr-0 text-right">
                    <label class="col-form-label">Herman@:</label>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-4"></div>
                  <div class="col-sm-4 pr-0 row">
                    <div class="col-sm-5">
                      <date-picker
                        class="w-100"
                        v-model="paso1.hermano_fecha"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                      ></date-picker>
                    </div>
                    <!-- <div class="col-sm-7">
                      <label class="col-form-label"><b>Edad: 30 años - 10 meses</b></label>
                    </div> -->
                  </div>
                </div>
                <div class="row mx-3">
                  <div class="col-sm-12 py-3 custom-bg-gray">
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Médico Remitente:</label>
                          <input type="text" class="form-control border-dashed">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Clinica Remitente:</label>
                          <input type="text" class="form-control border-dashed">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label>Motivo de Consulta:</label>
                          <textarea
                            class="form-control resize-none border-dashed"
                            rows="6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Antecedentes" title-link-class="px-4 py-2">
                <div class="row my-2">
                  <div class="col-sm-12">
                    <h5>
                      <b>ANTECEDENTES PRE, PERI Y POST-NATALES</b>
                    </h5>
                  </div>
                </div>
                <div class="row mx-3 mb-1">
                  <div class="col-sm-12 py-2 row custom-bg-gray">
                    <div class="col-sm-4">
                      <label class="col-form-label">
                        <b>Tiempo de Gestación:</b>
                      </label>
                    </div>
                    <div class="col-sm-5 row">
                      <div class="col-sm-4">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> A término </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Prematuro </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Postmaduro </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row col-sm-12 mb-3">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row mx-0 mb-1">
                      <div class="col-sm-4">
                        <label class="col-form-label">
                          <b>Embarazo:</b>
                        </label>
                      </div>
                      <div class="col-sm-5 row">
                        <div class="col-sm-4">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Normal </label>
                        </div>
                        <div class="col-sm-4">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Con Dificultad </label>
                        </div>
                        <div class="col-sm-4">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Alto Riesgo </label>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-1">
                        <label class="col-form-label">
                          Observación:
                        </label>
                      </div>
                      <div class="col-sm-11">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-6">
                        1. Enfermedades durante el embarazo (preeclampsia, eclampsia, diabetes gestacional):
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuál:</label>
                      </div>
                      <div class="col-sm-10 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-6">
                        2. Enfermedades eruptivas durante el embarazo:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuál:</label>
                      </div>
                      <div class="col-sm-10 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-6">
                        3. Consumo de medicamentos durante el embarazo:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuál:</label>
                      </div>
                      <div class="col-sm-10 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-6">
                        4. Accidentes/traumas durante el embarazo:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuál:</label>
                      </div>
                      <div class="col-sm-10 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-3 mb-1">
                  <div class="col-sm-12 py-2 row custom-bg-gray">
                    <div class="col-sm-3">
                      <label class="col-form-label">
                        <b>Parto:</b>
                      </label>
                    </div>
                    <div class="col-sm-9 row">
                      <div class="col-sm-2">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Normal </label>
                      </div>
                      <div class="col-sm-2">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Cesárea </label>
                      </div>
                      <div class="col-sm-2">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Inducido </label>
                      </div>
                      <div class="col-sm-2 px-1">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> Uso de fórceps </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox" class="align-middle">
                        <label class="col-form-label ml-2"> APGAR </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row col-sm-12 mb-3">
                  <div class="col-sm-12 py-3 custom-bg-gray">
                    <div class="row mx-0 mb-1">
                      <div class="col-sm-3">
                        <label class="col-form-label">
                          <b>Presentó:</b>
                        </label>
                      </div>
                      <div class="col-sm-9 row">
                        <div class="col-sm-2">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Hipoxia </label>
                        </div>
                        <div class="col-sm-2">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Anoxia </label>
                        </div>
                        <div class="col-sm-3">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Sufrimiento Fetal </label>
                        </div>
                        <div class="col-sm-2 pl-0">
                          <input type="checkbox" class="align-middle">
                          <label class="col-form-label ml-2"> Otros </label>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-1">
                        <label class="col-form-label">
                          Observación:
                        </label>
                      </div>
                      <div class="col-sm-11">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-2 pr-0">
                        <label class="col-form-label">
                          Enfermedades post natales:
                        </label>
                      </div>
                      <div class="col-sm-10">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Enfermedades" title-link-class="px-4 py-2">
                <div class="row my-2">
                  <div class="col-sm-12">
                    <h5>
                      <b>ENFERMEDADES DEL PACIENTE</b>
                    </h5>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        1. Es alérgico a algo:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">A qué:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        2. Se encuentra en tratamiento farmacológico:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-5 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                      <div class="col-sm-1">
                        <label class="col-form-label"> Por qué:</label>
                      </div>
                      <div class="col-sm-5 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        3. Enfermedades eruptivas:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuál:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        4. Enfermedades infecciosas:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        5. Enfermedades respiratorias:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row">
                      <div class="col-sm-5">
                        6. Tiene esquema de vacunación completo:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        7. Ha estado hospitalizado:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Por qué:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-3">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        8. Accidentes/traumas:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-3">
                  <div class="col-sm-5 py-2">
                    9. Duerme bien:
                  </div>
                  <div class="col-sm-1 py-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                      <label class="form-check-label h5">
                        <b>Si</b>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-1 py-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                      <label class="form-check-label h5">
                        <b>No</b>
                      </label>
                    </div>
                  </div>
                  <div class="offset-sm-1 col-sm-3 py-2 custom-bg-gray row">
                    <div class="col-sm-4">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                      <label class="form-check-label h5">
                        <b>Solo</b>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                      <label class="form-check-label h5">
                        <b>Acompañado</b>
                      </label>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="row">
                      <div class="col-sm-5">
                        10. Su sueño es:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Tranquilo</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Con sobresaltos</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>LLora</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Ronca</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Babea</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-sm-12">
                    <div class="row mb-2">
                      <div class="col-sm-5">
                        11. Ha recibido ayudas terapéuticas:
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label class="col-form-label">Qué tipo:</label>
                      </div>
                      <div class="col-sm-11 pl-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="form-group">
                      <label class="col-form-label">
                        <b>Observaciones:</b>
                      </label>
                      <textarea
                        class="form-control resize-none border-dashed"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Familiares y Alimentación" title-link-class="px-4 py-2">
                <div class="row mb-4">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>ANTECEDENTES FAMILIARES</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0 mb-4">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-5">
                            1. Alguien de su familia ha presentado problemas de lenguaje:
                          </div>
                          <div class="col-sm-1">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <label class="col-form-label">Quién:</label>
                          </div>
                          <div class="col-sm-5 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1">
                            <label class="col-form-label">Qué tipo:</label>
                          </div>
                          <div class="col-sm-5 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-4">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-5">
                            2. Alguien de su familia ha presentado problema auditivos:
                          </div>
                          <div class="col-sm-1">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <label class="col-form-label">Quién:</label>
                          </div>
                          <div class="col-sm-5 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1">
                            <label class="col-form-label">Qué tipo:</label>
                          </div>
                          <div class="col-sm-5 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-3">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-5">
                            3. Alguna enfermedad importante en la familia:
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <label class="col-form-label">Cuáles:</label>
                          </div>
                          <div class="col-sm-11 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>ALIMENTACIÓN</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">4. Lactancia materna:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">Cuánto tiempo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">5. Utilizó mamadera:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">Cuánto tiempo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>Tipo de Comida:</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-3">
                            <label class="col-form-label">6. Papilla:</label>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Edad:</label>
                          </div>
                          <div class="col-sm-1 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Cuál:</label>
                          </div>
                          <div class="col-sm-6 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-3">
                            <label class="col-form-label">7. Sólido:</label>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Edad:</label>
                          </div>
                          <div class="col-sm-1 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Cuál:</label>
                          </div>
                          <div class="col-sm-6 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-3">
                            <label class="col-form-label">8. Líquidos:</label>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Edad:</label>
                          </div>
                          <div class="col-sm-1 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Cuál:</label>
                          </div>
                          <div class="col-sm-6 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-2">
                            <label class="col-form-label">9. Actualmente:</label>
                          </div>
                          <div class="col-sm-10 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-6">
                            <label class="col-form-label">10. Desde que edad come solo:</label>
                          </div>
                          <div class="col-sm-6 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="col-form-label"><b>Rechaza algún alimento:</b></label>
                          </div>
                          <div class="col-sm-1 pl-0 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 pl-0 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Cuál:</label>
                          </div>
                          <div class="col-sm-5 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-2">
                            <label class="col-form-label">11. Alergias alimentarias:</label>
                          </div>
                          <div class="col-sm-1 pr-0 justify-content-end d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 pl-0 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">De qué tipo:</label>
                          </div>
                          <div class="col-sm-7">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>Problemas de Alimentación</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">12. Masticación:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">De qué tipo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">13. Deglución:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">De qué tipo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">14. Succión:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">De qué tipo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-12">
                        <div class="row mb-2">
                          <div class="col-sm-4">
                            <label class="col-form-label">15. Sorbición:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">De qué tipo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="form-group">
                      <label class="col-form-label">
                        <b>Observaciones:</b>
                      </label>
                      <textarea
                        class="form-control resize-none border-dashed"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Motor y Lenguaje" title-link-class="px-4 py-2">
                <div class="row mb-4">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>DESARROLLO MOTOR</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">1. Control cefálico:</label>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Edad:</label>
                          </div>
                          <div class="col-sm-1 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">2. A qué edad se sentó:</label>
                          </div>
                          <div class="col-sm-2 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-2 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Sin ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">3. A qué edad gateó:</label>
                          </div>
                          <div class="col-sm-2 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-2 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Sin ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">4. A qué edad caminó:</label>
                          </div>
                          <div class="col-sm-2 px-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-2 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Sin ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">5. Control de esfínteres:</label>
                          </div>
                          <div class="col-sm-1 pr-0 justify-content-end d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 pl-0 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0">
                            <label class="col-form-label">Cuál/Cuáles:</label>
                          </div>
                          <div class="col-sm-5 pr-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Edad:</label>
                          </div>
                          <div class="col-sm-1 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 py-2 custom-bg-gray">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">Observaciones:</label>
                          </div>
                          <div class="col-sm-10 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-sm-12">
                    <h5>
                      <b>DESARROLLO DEL LENGUAJE</b>
                    </h5>
                  </div>
                </div>
                <div class="row mx-0 mb-2">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">6. Edad de emisión de primeras palabras:</label>
                      </div>
                      <div class="col-sm-1 px-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                      <div class="col-sm-1 text-right">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-7">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-2">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">7. Edad de emisión de frases:</label>
                      </div>
                      <div class="col-sm-1 px-0">
                        <input type="text" class="form-control border-dashed">
                      </div>
                      <div class="col-sm-1 text-right">
                        <label class="col-form-label">Cuáles:</label>
                      </div>
                      <div class="col-sm-7">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">8. Tiene intención comunicativa:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2 px-0">
                        <label class="col-form-label">Tiene contacto visual:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">9. Inicia una conversación:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2 px-0">
                        <label class="col-form-label">Mantiene una conversación:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">10. Sigue instrucciones:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2 justify-content-center d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Simples</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Complejas</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3 pr-0">
                        <label class="col-form-label">11. Utiliza el señalamiento para comunicarse:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 py-1 custom-bg-gray">
                    <div class="row mx-0">
                      <div class="col-sm-3">
                        <label class="col-form-label">12. Reacciona ante los estímulos auditivos:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <label class="col-form-label">Cree que escucha Bien:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 py-1 custom-bg-gray">
                    <div class="row mx-0">
                      <div class="col-sm-3">
                        <label class="col-form-label">13. Tiene estudio auditivo:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 pr-0">
                        <label class="col-form-label">Cuál/Cuáles:</label>
                      </div>
                      <div class="col-sm-6">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12 py-1 custom-bg-gray">
                    <div class="row mx-0">
                      <div class="col-sm-3">
                        <label class="col-form-label">14. Ha tenido patología auditiva:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 pr-0">
                        <label class="col-form-label">Cuál/Cuáles:</label>
                      </div>
                      <div class="col-sm-6">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">15. Tiene problemas de articulación:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 pr-0">
                        <label class="col-form-label">De qué tipo:</label>
                      </div>
                      <div class="col-sm-6">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-2">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="col-form-label">16. Tiene problemas de comprensión:</label>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>Si</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 d-flex align-middle">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                          <label class="form-check-label h5">
                            <b>No</b>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-1 pr-0">
                        <label class="col-form-label">De qué tipo:</label>
                      </div>
                      <div class="col-sm-6">
                        <input type="text" class="form-control border-dashed">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-2">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-5 pr-0">
                            <label class="col-form-label">17. Tartamudea:</label>
                          </div>
                          <div class="col-sm-3 pr-1 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-3 pl-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="col-form-label">Tensa la cara:</label>
                          </div>
                          <div class="col-sm-3 pr-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-3 pl-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="col-form-label">Tensa el cuello:</label>
                          </div>
                          <div class="col-sm-3 pr-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-3 pl-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 pl-0">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">Otros:</label>
                          </div>
                          <div class="col-sm-9 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-2">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-8">
                            <label class="col-form-label">18. Tartamudea al inicio de la palabra:</label>
                          </div>
                          <div class="col-sm-2 pr-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pl-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="offset-sm-1 col-sm-5">
                            <label class="col-form-label">Al final de la palabra:</label>
                          </div>
                          <div class="col-sm-2 pr-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pl-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="form-group">
                      <label class="col-form-label">
                        <b>Observaciones:</b>
                      </label>
                      <textarea
                        class="form-control resize-none border-dashed"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="AVD, Desarrollo Social y Escolar" title-link-class="px-4 py-2">
                <div class="row mb-4">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>ACTIVIDADES DE LA VIDA DIARIA (AVD)</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">1. Se baña:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 text-right">
                            <label class="col-form-label">Se viste:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">2. Come:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 text-right">
                            <label class="col-form-label">Amarrado de zapatos:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">3. Cepillado:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 text-right">
                            <label class="col-form-label">Entra al baño:</label>
                          </div>
                          <div class="col-sm-1 pr-0 d-flex align-middle justify-content-end">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con ayuda</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 py-2 custom-bg-gray">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">Observaciones:</label>
                          </div>
                          <div class="col-sm-10 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-sm-12 border border-light">
                    <div class="row my-2">
                      <div class="col-sm-12">
                        <h5>
                          <b>DESARROLLO SOCIAL Y ESCOLAR</b>
                        </h5>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">4. Cómo se relaciona con otros niños:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Bien</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Le cuesta</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pl-0 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Necesita Tiempo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 pr-0 text-right">
                            <label class="col-form-label">Otros:</label>
                          </div>
                          <div class="col-sm-3">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">5. Juega:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Solo</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con otros niños</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-3 pr-0 text-right">
                            <label class="col-form-label">Juegos preferidos:</label>
                          </div>
                          <div class="col-sm-3">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">6. En qué ocupa el tiempo libre:</label>
                          </div>
                          <div class="col-sm-9">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">7. Asiste a Guardería:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 text-right">
                            <label class="col-form-label">Colegio:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 px-0 text-right">
                            <label class="col-form-label">Grado Escolar:</label>
                          </div>
                          <div class="col-sm-3">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">8. Cuanto tiempo:</label>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Turno Mañana</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Turno Tarde</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pr-0 text-right">
                            <label class="col-form-label">Otros:</label>
                          </div>
                          <div class="col-sm-3">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">9. Como fue la adaptación:</label>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Fácil</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Con dificultad</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 pr-0 text-right">
                            <label class="col-form-label">Observación:</label>
                          </div>
                          <div class="col-sm-4">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">10. Ha repetido algún año:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pr-0 text-right">
                            <label class="col-form-label">Qué año:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">11. Cómo se comporta en la institución:</label>
                          </div>
                          <div class="col-sm-9">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-3">
                            <label class="col-form-label">12. Tiene maestra integradora:</label>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>Si</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-1 d-flex align-middle">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                              <label class="form-check-label h5">
                                <b>No</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-2 pr-0 text-right">
                            <label class="col-form-label">Por cuanto tiempo:</label>
                          </div>
                          <div class="col-sm-5">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 py-2 custom-bg-gray">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="col-form-label">Observaciones:</label>
                          </div>
                          <div class="col-sm-10 pl-0">
                            <input type="text" class="form-control border-dashed">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-3">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="form-group">
                      <label class="col-form-label">
                        <b>
                          Otros tratamientos (Cuánto tiempo duró, que profesionales le atendieron,
                          motivo de egreso, que le gustó y que no le gustó de ese lugar, profesionales y servicio):
                        </b>
                      </label>
                      <textarea
                        class="form-control resize-none border-dashed"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-sm-12 py-2 custom-bg-gray">
                    <div class="form-group">
                      <label class="col-form-label">
                        <b>Observaciones:</b>
                      </label>
                      <textarea
                        class="form-control resize-none border-dashed"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import Multiselect from 'vue-multiselect'; */
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'formulario1',
  components: {
    DatePicker,
    /* Multiselect, */
  },
  data() {
    return {
      paso1: {
        fecha: null,
        fechaNacimiento: null,
        padre_fecha: null,
        madre_fecha: null,
        hermano_fecha: null,
      },
    };
  },
};
</script>

<style scoped>
.custom-bg-gray {
  background: #eee;
}
.border-dashed{
  border-style: dashed;
  border-width: 1px;
  border-color: gray;
}
.resize-none {
  resize: none;
}
</style>
